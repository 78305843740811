import React, { useEffect } from "react"

function ExternalScriptLoader({ src, children }) {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = src
    script.async = true

    const div = document.getElementById("esl")
    div.prepend(script)

    return () => {
      div.removeChild(script)
    }
  }, [])

  return <div id="esl">{children}</div>
}

export default ExternalScriptLoader
