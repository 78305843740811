import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Columns, Section } from "../components/Containers"
import SEO from "../components/seo"
import ExternalScriptLoader from "../components/ExternalScriptLoader"
import { Button } from "../components/Button"

const PaymentPlans = ({ pageContext }) => {
  const { language } = pageContext

  return (
    <Layout language={language} navSpacer>
      <SEO
        lang={language}
        title="Payment Plans"
        description="Payment Plans at Oral Surgery Specialists of Oklahoma."
      />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div style={{ maxWidth: "900px", marginInline: "auto", marginTop: 150 }}>
        <Section>
          <h1 className="px-40--mobile">Payment Plans</h1>
          <p>
            We offer payment plans with{" "}
            <a href="#carecredit">
              CareCredit<sup>®</sup>
            </a>{" "}
            and <a href="#cherry">Cherry</a>.
          </p>
        </Section>

        <Section colorBack>
          <Columns sideColumnsSize={3}>
            <div id="carecredit" className="column">
              <h2 className="mt-0">
                CareCredit<sup>®</sup>
              </h2>
              <p>
                Our office is an enrolled CareCredit provider. CareCredit is a
                credit card that you can use to cover out-of-pocket medical
                expenses that are not covered by insurance. It takes only
                minutes to apply and once approved, you can use your CareCredit
                credit card at any enrolled provider nationwide. You determine
                the short or long-term repayment option that fits your
                lifestyle, and CareCredit is there to help you afford the care
                you need. You can use your credit card for dental care, at
                hospitals and surgical centers, and even to care for your pet.
                Once approved, you will not need to reapply. There is no
                activation fee.
              </p>
              <a className="standard-button contained mx-auto--mobile">
                Apply Now
              </a>
            </div>
          </Columns>
        </Section>

        <Section>
          <div id="cherry" className="payment-plans">
            <h2 className="mt-0">Cherry</h2>
          </div>

          <ExternalScriptLoader src="/cherry.js">
            <div id="all"></div>
          </ExternalScriptLoader>
        </Section>
      </div>
    </Layout>
  )
}

export default PaymentPlans
